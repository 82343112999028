import styled from "styled-components";

// Styled-component for the card container
const CardStyleWrapper = styled.div`
  width: 240px;
  background-color: white;
  border: 1px solid #D4D3D3;
  border-radius: 24px;
  margin: 1px 2px;
  position: relative;

  .image-container {
    background-color: white;
    width: 100%;
    height: 58%;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      border: 2px solid black;
      border-radius: 16px;
      margin-bottom: 20px;
    }
  }

  .rarity-banner {
    background-color: ${(props) => props.rarityColor};
    color: black;
    padding: 1px 2px;
    position: absolute;
    top: 2px;
    right: 2px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
  }

  .content {
    padding: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 40%;

    h1 {
      font-size: 18px;
      font-weight: bold;
      margin-top: 4px;
      font-family: 'VT323', monospace;
      color: black;
    }

    .details {
      display: flex;
      margin-top: 2px;
      color: #000000;
      font-size: 14px;
      font-family: 'VT323', monospace;
      font-weight: medium;

      p {
        margin-right: 2px;
        color: #000000;

      }

      /* You can add styles for the rarity color circle here if needed */
    }
  }
`;

export default CardStyleWrapper;
