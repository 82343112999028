import React, { useState, useEffect } from "react";
import Card from "./Card";
import Stats from "./Stats";
import firebase from "./Firebase";
import CardListStyleWrapper from "./CardListStyle"; // Import the styled component
import searchIcon from "../../../assets/images/nft/searchIcon.svg";
const CardList = () => {
  const [searchText, setSearchText] = useState("");
  const [isPlaceholderVisible, setPlaceholderVisible] = useState(true);
  const [selectedType, setSelectedType] = useState("");
  const [cards, setCards] = useState([]);

  const handleInputFocus = () => {
    setPlaceholderVisible(false);
  };

  const handleInputBlur = () => {
    if (searchText === "") {
      setPlaceholderVisible(true);
    }
  };

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const db = firebase.firestore();
      const layerCollection = db.collection("layers");

      try {
        let query = layerCollection;

        if (selectedType) {
          query = query.where("type", "==", selectedType);
        } else {
          query = query.where("type", "==", "Skin");
        }

        const querySnapshot = await query.get();
        const layerData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCards(layerData);
      } catch (error) {
        console.error("Error fetching data from Firestore:", error);
      }
    };

    fetchData();
  }, [selectedType]);

  return (
    <CardListStyleWrapper>
      <div className="relative">
        {/* <Stats /> */}

        {/* <div className="search-container">
          <img
            src={searchIcon}
            alt="Search Icon"
            className="search-icon"
          />
          <input
            type="text"
            className="search-input"
            placeholder={isPlaceholderVisible ? "Search" : ""}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
            value={searchText}
          />
        </div> */}

        <div className="filter-dropdown">
          <div>
            <span className="filter-label">Layer:</span>
            <select
              className="filter-select"
              onChange={(e) => setSelectedType(e.target.value)}
              value={selectedType}
            >
              <option value="Skin">Skin</option>
              <option value="background">Background</option>
              <option value="Clothes">Clothes</option>
              <option value="Eyes">Eyes</option>
              <option value="Head">Head</option>
              <option value="Mouth">Mouth</option>
              <option value="Necklace">Necklace</option>
              <option value="Speciality">Speciality</option>
            </select>
          </div>
        </div>

        <div className="card-container">
          <div className="card-grid">
            {cards.map((card) => (
              <Card key={card.id} data={card} />
            ))}
          </div>
        </div>
      </div>
    </CardListStyleWrapper>
  );
};

export default CardList;
