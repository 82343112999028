import React from 'react';
import FooterBottom from "./footerBottom/FooterBottom";
import data from "../../../../assets/data/footer/footerDataV3";
import footerLogo from "../../../../assets/images/logo.png";
import btnArrow from "../../../../assets/images/blog/arrow-icon.png";
import FooterStyleWrapper from "./Footer.style";
import Button from "../../../../common/button";

const Footer = () => {
  const handleDownloadWhitepaper = () => {
    const pdfUrl = process.env.PUBLIC_URL + '/pdf/whitepaper.pdf';
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'whitepaper.pdf';
    link.click();
  };

  return (
    <FooterStyleWrapper>
      <div className="top-footer-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="footer_image">
                <a href="# ">
                  <img src={footerLogo} alt="bithu nft footer logo" />
                </a>
                <h3>PulseGods NFT</h3>
                <p>
                  The PulseGods project is subject to change and development. The whitepaper outlines the project's current objectives and features, but these may evolve over
time.

                </p>
              </div>
            </div>
            {/* Add your other footer content here */}
            {/* ... */}
            
            <div className="col-md-6 col-lg-4">
              <h3>Download our Whitepaper</h3>
              <p>
                Explore our comprehensive whitepaper to learn more about our vision, technology, and future plans.
              </p>
              <div className="bithu_v3_banner_buttons">
                <Button lg variant="mint" onClick={handleDownloadWhitepaper}>
                  Download Whitepaper
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* footer bottom */}
      <FooterBottom />
    </FooterStyleWrapper>
  );
};

export default Footer;
