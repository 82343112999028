import React from "react";
import CardStyleWrapper from "./CardStyle"; // Import the styled component

const Card = ({ data }) => {
  const rarityColors = {
    Uncommon: "#E6E6FA",
    Common: "#E6E6FA",
    Rare: "#0000FF",
    "Super Rare": "#800080",
    Mythic: "#800080",
  };

  const rarityColor = rarityColors[data.rarity] || "#ffffff";

  return (
    <CardStyleWrapper rarityColor={rarityColor}>
      <div className="image-container">
        <img src={data.url} alt={data.name} />
      </div>
      <div className="rarity-banner">{data.rarity}</div>
      <div className="content">
        <div>
          <h1>{data.name}</h1>
          <div className="details">
            <p>Rarity: {data.rarity}</p>
            {/* Add the rarity color circle here if needed */}
          </div>
        </div>
      </div>
    </CardStyleWrapper>
  );
};

export default Card;
