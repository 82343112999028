const data = [
  {
    title: "Minting Cost and Allocation of Fees",
    text: "To become a part of the PulseGods NFT Collection, collectors can mint NFTs for a cost of 555,555 $PLS tokens. This cost ensures exclusivity and contributes to the development and sustainability of our ecosystem.",
  },
  {
    title: "NFT Staking and $Gee Token",
    text: "NFT staking introduces a novel way for collectors to engage with their PulseGods NFTs beyond mere ownership. It allows NFT holders to participate actively in the ecosystem by staking their NFTs in exchange for $Gee tokens as a reward. This innovative approach not only empowers collectors but also ensures the sustainability and vibrancy of our ecosystem.",
  },
  {
    title: "Staking Mechanism    ",
    text: "NFT staking offers collectors a way to earn passive income within the PulseGods ecosystem. Collectors can stake their PulseGods NFTs within the PulseGods ecosystem. Staking involves temporarily locking NFTs into a smart contract, making them unavailable for trade or transfer during the staking period. In return, Stakers earn $Gee tokens as rewards for participating in NFT staking. Staked NFTs remain the property of their owners, which means that collectors can unstake their NFTs at any time. This flexibility ensures that collectors can adapt to changing circumstances or explore new opportunities as they arise.",
  },
  {
    title: "Staking Reward",
    text: "Staking PulseGods NFTs is not just a commitment; it's a rewarding experience. Stakers are entitled to receive $Gee tokens as rewards for their active participation. The reward mechanism is based on an emission rate of 15 $Gee tokens per day per NFT staked. This dynamic system encourages ongoing engagement and participation within the PulseGods community.",
  },
  {
    title: "$Gee Token",
    text: "$Gee tokens serve as the lifeblood of the PulseGods ecosystem. They are generated through the act of staking PulseGods NFTs. As collectors stake their NFTs, $Gee tokens are minted at a predefined rate, thus creating a sustainable token supply that is intrinsically tied to the activity within our ecosystem. The total supply of $Gee tokens is fixed at 55,500,000 tokens. This carefully curated supply is designed to strike a balance between scarcity and accessibility, ensuring that $Gee tokens maintain value over time.",
  },
  {
    title: "Staking $Gee Tokens",
    text: "The holder of the $Gee token will be able to stake tokens. In return, they are eligible to receive a share of the fees generated from transactions on TradeGod. By staking $Gee tokens, collectors actively contribute to the liquidity and stability of the platform.",
  },
];

export default data;
