import styled from "styled-components";

// Styled-component for the Stats component
const StatsStyleWrapper = styled.div`
  .stats-container {
    justify-content: flex-end;
    gap: 6;
    flex: mt-4;
  }

  .stats-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .stats-label {
      color: #B4B4B4;
      font-size: 14px;
      font-weight: medium;
      font-family: 'Poppins', sans-serif;
    }

    .stats-value {
      display: flex;
      align-items: center;
      gap: 1;

      .eth-icon {
        height: 14px;
        width: 14px;
      }

      span {
        color: black;
        font-size: 16px;
        font-weight: bold;
        font-family: 'Poppins', sans-serif;
      }
    }
  }
`;

export default StatsStyleWrapper;
