import {useModal} from "../utils/ModalContext"
import GlobalStyles from "../assets/styles/GlobalStyles";
import Layout from "../common/layout";
import Header from "../components/section/header/v1/Header";
import Banner from "../components/section/banner/v3";
import CharacterSlider from "../components/section/characterSlider/v3";
import About from "../components/section/about/v3";
import RoadMap from "../components/section/roadMap/v3";
import Team from "../components/section/team/v3";
import Footer from "../components/section/footer/v3";
import Faq from "../components/section/faq/v3";

const HomeV3 = () => {
  const { visibility, walletModalvisibility } = useModal();
  return (
    <>
      <Layout>
      <GlobalStyles />
      {visibility }
        <Header />
        <Banner />
        <CharacterSlider />
        <About />
        <RoadMap />
        <Faq />

        {/* <Team /> */}
        <Footer />
      </Layout>
    </>
  );
};

export default HomeV3;
