import React, { Component } from 'react';
import axios from 'axios';
import { getEthereumContract } from '../services/blockchain';
import { truncate, useGlobalState } from '../store'
import Blogs from "../../../pages/blogs";

const connectedAccount = "0x910dB2813DF3BCaf370290736a4696D9ffFB6d65"; // Your connected account address
var vaultcontract = "0x910dB2813DF3BCaf370290736a4696D9ffFB6d65";

class Staking extends Component {

  constructor() {
    super();
    this.state = {
      nfts: [],
      // ... (other state variables)
    };
  }

  async componentDidMount() {
    this.getWalletNFTs();
  }

  async getWalletNFTs() {
    const contract = await getEthereumContract();
    const totalSupply = await contract.totalSupply();

    const promises = Array.from({ length: totalSupply }, async (_, i) => {
      const tokenId = i + 1;

      try {
        const owner = await contract.ownerOf(tokenId);

        if (owner.toLowerCase() === connectedAccount.toLowerCase()) {
          const rawUri = await contract.tokenURI(tokenId);
          const cleanUri = rawUri.replace('ipfs://', 'https://ipfs.io/ipfs/');
          const metadata = await axios.get(cleanUri);
          const { name, description, image: rawImage } = metadata.data;
          const image = rawImage.replace('ipfs://', 'https://ipfs.io/ipfs/');

          return {
            name,
            description,
            image,
            tokenId,
            owner,
          };
        }
      } catch (error) {
        console.error(`Error fetching data for token ID ${tokenId}: `, error);
      }

      return null;
    });

    const allNfts = await Promise.all(promises);
    const ownedNfts = allNfts.filter(Boolean);
    this.setState({
      nfts: ownedNfts,
      // ... (update other state variables if needed)
    });
  }

  render() {
    const { nfts } = this.state;

    return (
      <div className="App nftapp">
        <nav className="navbar navbarfont navbarglow navbar-expand-md navbar-dark bg-dark mb-4">
          <div className="container-fluid" style={{ fontFamily: "SF Pro Display" }}>
            <a className="navbar-brand px-5" style={{ fontWeight: "800", fontSize: '25px' }} href="#">
              {/* Replace with your brand/logo */}
              <img src="your-logo.png" width="7%" alt="Logo" />
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <ul className="navbar-nav me-auto mb-2 px-3 mb-md-0" style={{ fontSize: "25px" }}>
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="#">Dashboard</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">List NFTs</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link">Bridge NFTs</a>
                </li>
              </ul>
            </div>
          </div>
          <div className='px-5'>
            <input id="connectbtn" type="button" className="connectbutton" style={{ fontFamily: "SF Pro Display" }} value="Connect Your Wallet" />
          </div>
        </nav>
        <div className='container container-style'>
          <div className='col'>
            <body className='nftminter'>
              <form>
                {/* ... (your form and input elements) */}
              </form>
            </body>
          </div>
          <div className='col'>
            <body className='nftstaker border-0'>
              <form style={{ fontFamily: "SF Pro Display" }}>
                {/* ... (your form and input elements) */}
              </form>
            </body>
          </div>
        </div>
        <div className="container col-lg-11">
  <div className="row items px-3 pt-3" style={{ display: "grid", gridTemplateColumns: "repeat(4, 5fr)", columnGap: "20px" }}>
    {nfts.map((nft, i) => {
    //   async function stakeit() {
    //     vaultcontract.methods.stake([nft.tokenId]).send({ from: connectedAccount });
    //   }
    //   async function unstakeit() {
    //     vaultcontract.methods.unstake([nft.tokenId]).send({ from: connectedAccount });
    //   }
      return (
        <div className="card nft-card mt-3" key={i} style={{ width: "18rem" }}>
          <div className="image-over" style={{ maxHeight: "200px", overflow: "hidden" }}>
            <img className="card-img-top" src={nft.image} alt="" style={{ objectFit: "cover", width: "100%", height: "100%" }} />
          </div>
          <div className="card-caption col-12 p-0">
            <div className="card-body">
              <h5 className="card-title">Net2Dev Collection NFT #{nft.tokenId}</h5>
              <h6 className="card-subtitle mb-2 text-muted">Location Status</h6>
              <p className="card-text" style={{ color: "#39FF14", fontWeight: "bold", textShadow: "1px 1px 2px #000000" }}>{nft.owner}</p>
              <div className="card-bottom d-flex justify-content-between">
                <input key={i} type="hidden" id='stakeid' value={nft.tokenId} />
                <button style={{ marginLeft: '2px', backgroundColor: "#ffffff10" }} 
                // onClick={stakeit}
                >Stake it</button>
                <button style={{ marginLeft: '2px', backgroundColor: "#ffffff10" }} 
                // onClick={unstakeit}
                >Unstake it</button>
              </div>
            </div>
          </div>
        </div>
      );
    })}
  </div>
</div>


      </div>
    );
  }
}

export default Staking;
