import {
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaTelegramPlane,
  FaFacebook,
} from "react-icons/fa";

import openseaIcon from "../images/icon/opensea.svg";
import mediumIcon from "../images/icon/med.svg";

const data = [
  {
    icon: <FaTwitter />,
    url: "https://x.com/PulseGodsNFTs?t=gq3o0gRJ40YpaeYcWjgJ8w&s=09",
  },
  {
    icon: <FaTelegramPlane />,
    url: "https://t.me/PulseGodsNFT",
  }
];

export default data;
