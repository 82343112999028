import { useState, useEffect } from "react";
import { useModal } from "../../../../utils/ModalContext";
import characterThumb from "../../../../assets/images/nft/6.png";
import GIF1 from "../../../../assets/images/nft/GIF1.gif";

import { ethers } from 'ethers';
import MetlomicsNFT from './MetlomicsNFT.json';

import Button from "../../../../common/button";

import BannerStyleWrapper from "./Banner.style";
const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');
const tokens = require('./tokens.json');

const GGaddress = "0x78825BC703dA11e6029480ED9B2257F64E366033";

let quantity;




const Banner = () => {
  const [data, setData] = useState({});
  const [error, setError] = useState('');

  const [count, setCount] = useState(1);
  quantity = parseInt(count);

  useEffect(() => {
    fetchData();
   // getAccounts();
   }, []);


  async function fetchData() {
    if (typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(GGaddress, MetlomicsNFT.abi, provider);
      try {
        const costp = await contract.publicSalePrice(); 

        // const totalSupply = await contract.totalSupply();
        const object = {"costp": String(costp) }
        setData(object);
      }
      catch (err) {
        /*setError(err.message);*/
      }
    }
  }


   async function publicSaleMint() {
    if (typeof window.ethereum !== 'undefined') {
      let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(GGaddress, MetlomicsNFT.abi, signer);

      try {
        let overrides = {
          from: accounts[0],
          value: ethers.utils.parseUnits('350000', 'ether').mul(quantity)
        }
        const transaction = await contract.publicSaleMint(accounts[0], quantity, overrides);
        await transaction.wait();
        fetchData();
      }
      catch (err) { 
        if (err.reason === 'insufficient funds for intrinsic transaction cost'){
          alert("Sorry, Insufficient funds");
         // alert(Thedata.costp * quantity);
        }
        setError(err.message);
        console.log(err);
        console.log(data.costp);


      }
    }
  }

  async function mintWhitelist() {
    if (typeof window.ethereum !== 'undefined') {
      let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(GGaddress, MetlomicsNFT.abi, signer);


      let tab = [];
      tokens.map(token => {
        tab.push(token.address)
      })

      const leaves = tab.map(address => keccak256(address));
      const tree = new MerkleTree(leaves, keccak256, { sort: true });
      const leaf = keccak256(accounts[0]);
      const proof = tree.getHexProof(leaf);

      try {
        let overrides = {
          from: accounts[0],
          value: '' + data.cost * quantity + ''


        }

        const transaction = await contract.presaleMint(accounts[0], quantity, proof, overrides);
        await transaction.wait();
        fetchData();
      }

      catch (err) {
        console.log(err.message);
       // alert(err.reason);



      }

    }
  }










  return (
    <BannerStyleWrapper className="bithu_v3_baner_sect" id="home">
      
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="banner-image-area3">
              {/* particles component */}
              <div className="bithu_v1_baner_right_img">
                  <img src={GIF1} alt="GIF1" />
                </div>
              
          
            </div>
          </div>
          <div className="col-lg-6">
            <div className="banner-conent3">
             
              <h1 className="banner-title text-uppercase">PulseGods NFT MINT Is LIVE NOW</h1>
              <h2> MORE THAN 370 NFT MINTED</h2>

              <div className="bithu_v3_timer">
                <h5 className="text-uppercase"></h5>
                <div className="timer timer_1">
                </div>
              </div>
              <div className="banner-count-inner d-flex align-items-center">

                <div className="banner-btn-area">
                  <span
                    className="input-number-decrement"
                    onClick={() => (count > 1 ? setCount(count - 1) : count)}
                  >
                    –
                  </span>
                  <input
                    className="input-number"
                    type="text"
                    value={count}
                    onChange={(e) => setCount(e.target.value)}
                  />
                  <span
                    className="input-number-increment"
                    onClick={() => setCount(count + 1)}
                  >
                    +
                  </span>
                </div>
                <div className="bithu_v3_baner_buttons">
                  <Button lg variant="mint" 
                                  onClick={publicSaleMint}> 
                    Mint Now
                  </Button>
                </div>
              </div>

              
              <div className="banner-bottom-text text-uppercase">
                PublicSale Mint is Live Now!
              </div>
              <span> 1 PulseGod costs 350,000 $PLS</span>

            </div>
          </div>
        </div>
      </div>
    </BannerStyleWrapper>
  );
};

export default Banner;
