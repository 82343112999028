import aboutThumb1 from "../../images/nft/GIF2.gif";
import aboutIcon from "../../images/icon/ab-icon-image.png";

const data = {
  aboutDescription1:
  "PulseGods NFT Collection represents the heart and soul of our project. At its core, the PulseGods NFT Collection is a testament to creativity and innovation. Comprising 10,000 unique NFTs, each one is a digital masterpiece, bearing the distinct mark of our commitment to quality and artistry. These NFTs are not just tokens; they are a gateway to a world of imagination and exclusivity. NFT collections are designed to offer collectors and enthusiasts an unparalleled experience in the world of NFTs.",
  aboutDescription2:
"Traits and Rarities What sets PulseGods NFTs apart are the over 230 unique traits and various rarities they possess. These traits range from visual elements, such as backgrounds, colors, and styles, to conceptual features, including themes and narratives. The combination of these traits results in an astonishing variety of NFTs, each with its own identity. Rarity is a cornerstone of PulseGods NFTs. Our collection includes common, rare, and ultra-rare NFTs, adding an element of excitement and exclusivity for collectors. These rarities are not just cosmetic; they impact the value and desirability of each NFT, making the collection an ever-evolving tapestry of digital art.",
  artists: [
    {
      thumb: aboutThumb1,
      icon: aboutIcon,
      name: "PulseGods",
    }
  
  ],
};

export default data;
