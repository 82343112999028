import styled from "styled-components";

const CardListStyleWrapper = styled.div`
  background-color: white; /* Set the background color to white */

  .stats {
    /* Add styles for your Stats component here */
  }

  .search-container {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
  }

  .search-icon {
    width: 18px;
    height: 18px;
    margin-right: 0.5rem;
  }

  .search-input {
    border-bottom: 1px solid #B4B4B4;
    outline: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #B4B4B4;
    padding: 0 0.25rem;
    flex-grow: 1;
  }

  .filter-dropdown {
    margin-top: 9.5rem;
    display: flex;
    align-items: center;
    border: 2px solid #B4B4B4;
    border-radius: 10px;
    padding: 0.4rem;
    margin-left: 0.5rem;
    max-width: 200px; /* Set a maximum width for the filter dropdown */
  }

  .filter-label {
    color: #B4B4B4;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    margin-right: 0.25rem;
  }

  .filter-select {
    color: #B4B4B4;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    border: none;
    padding: 0.1rem 0.5rem;
    margin-left: 0.25rem;
  }

  .card-container {
    background-color: white; /* Set the background color to white */
    border: 2px solid #B4B4B4;
    border-radius: 11px;
    padding: 1rem 2rem;
    margin-top: 1rem;
    position: relative;
    overflow: hidden;
  }

  .card-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    @media (min-width: 640px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1280px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  /* Add additional styles for your Card component here if needed */
`;

export default CardListStyleWrapper;
