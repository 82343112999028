import checkIcon from "../../images/blog/arrow.png";

const data = [
  {
    progressIcon: checkIcon,
    progress: "25",
    title: "Website Launch NFT Minting",
    
  },
  {
    progressIcon: checkIcon,
    progress: "50",
    title: "NFT Staking $Gee Token Generation",
  },
  {
    progressIcon: checkIcon,
    progress: "75",
    title: "Introduction of Seasons",
  },
  {
    progressIcon: checkIcon,
    progress: "100",
    title: "TradeGod Unveiled $Gee Tokens Staking",
  }
];

export default data;
