import { Routes, Route } from "react-router-dom";
import HomeV3 from "../pages/homeV3";
import HomeV33 from "../pages/homeV33";

// import CardList from "../pages/CardList";
import CardList from "../pages/CardList";
import Staking from "../components/section/staking/Staking"
import Blogs from "../pages/blogs";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomeV3 />} exact />
      <Route path="/explore" element={<CardList />} />

      {/* <Route path="/Staking" element={<Staking />} /> */}
      {/* <Route path="/Staking" element={<HomeV33 />} exact />
      <Route path="/blogs" element={<Blogs />} /> */}

    </Routes>
  );
}

export default App;
