import abi from './Auction.json'
import address from './contractAddress.json'
import { ethers } from 'ethers'
import { getGlobalState, setGlobalState } from '../store'
import axios from 'axios';

const { ethereum } = window
const ContractAddress = address.address
const ContractAbi = abi.abi
let tx

const toWei = (num) => ethers.utils.parseEther(num.toString())
const fromWei = (num) => ethers.utils.formatEther(num)

const getEthereumContract = async () => {
  try {
    const provider = new ethers.providers.Web3Provider(ethereum);
    
    // Check if an account is connected
    const accounts = await provider.listAccounts();
    if (accounts.length === 0) {
      throw new Error("No account connected");
    }
    
    // Get the signer only after confirming an account is connected
    const signer = provider.getSigner();
    
    const contract = new ethers.Contract(ContractAddress, ContractAbi, signer);
    return contract;
  } catch (error) {
    // You might want to log this error or handle it in some other way
    console.log(error);
    throw error;  // Propagate the error up to the calling function

  }
}



const isWalletConnected = async () => {
  try {
    if (!ethereum) return alert('Please install Metamask')

    const accounts = await ethereum.request({ method: 'eth_accounts' })
    setGlobalState('connectedAccount', accounts[0]?.toLowerCase())

    window.ethereum.on('chainChanged', (chainId) => window.location.reload())

    window.ethereum.on('accountsChanged', async () => {
      setGlobalState('connectedAccount', accounts[0]?.toLowerCase())
      await isWalletConnected()
      await loadCollection()
    })

    if (accounts.length) {
      setGlobalState('connectedAccount', accounts[0]?.toLowerCase())
    } else {
      alert('Please connect wallet')
      console.log('No accounts found')
    }
  } catch (error) {
    reportError(error)
    alert("Please connect to PS")
  }
}
const connectWallet = async () => {
  try {
    if (!ethereum) return alert('Please install Metamask');
    const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
    if (accounts.length === 0) throw new Error("No account connected");
    setGlobalState('connectedAccount', accounts[0]?.toLowerCase());
  } catch (error) {
    reportError(error);
    // Add appropriate handling here, such as showing a user-friendly message
    console.log("Please connect your wallet");
  }
}


const checkIfWhitelisted = async (connectedAccount) => {
  if (!ethereum) throw new Error('Please install Metamask');
  const contract = await getEthereumContract();

  const isWhitelisted = await contract.isWhitelisted(connectedAccount);

  return isWhitelisted;
}

const createNFTItem = async (count) => {
  try {

  if (!ethereum) throw new Error('Please install Metamask')
  const connectedAccount = getGlobalState('connectedAccount')
  const contract = await getEthereumContract()
  console.log("Count:", count);
  let value;
  value = toWei(count * 150000);


  const tx = await contract.Mint(
    count,
    {
      from: connectedAccount,
      value: value,
    }
  )

  await tx.wait()
} catch (error) {
  console.log("error  " +error)
  reportError(error);
  throw error;  // This will propagate the error back to the toast.promise

}
}

const createNFTItemFree = async (isWhitelisted) => {
  try {

  if (!ethereum) throw new Error('Please install Metamask')
  const connectedAccount = getGlobalState('connectedAccount')
  const contract = await getEthereumContract()
  console.log("Is whitelisted:", isWhitelisted);
  let value;
  value = toWei(0);


  const tx = await contract.freeMint(
    
    {
      from: connectedAccount,
      value: value,
    }
  )

  await tx.wait()
} catch (error) {
  console.log("error  " +error)
  reportError(error);
  throw error;  // This will propagate the error back to the toast.promise

}
}



const updatePrice = async (tokenId, price) => {
  try {
    if (!ethereum) return alert('Please install Metamask')
    const connectedAccount = getGlobalState('connectedAccount')
    const contract = await getEthereumContract()

    tx = await contract.chanagePrice(tokenId, toWei(price), {
      from: connectedAccount,
    })

    await tx.wait()
    await loadCollection()
  } catch (error) {
    // reportError(error)
  }
}

const purchaseNFT = async ({ tokenId, price }) => {
  try {
    if (!ethereum) return alert('Please install Metamask')
    const connectedAccount = getGlobalState('connectedAccount')
    const contract = await getEthereumContract()

    tx = await contract.buyAuctionedItem(tokenId, {
      from: connectedAccount,
      value: toWei(price),
    })

    await tx.wait()
    await loadLiveLiveAuctions()
  } catch (error) {
    reportError(error);
    throw error;  // This will propagate the error back to the toast.promise

  }
}

const placeBid = async ({ tokenId, price }) => {
  try {
    if (!ethereum) return alert('Please install Metamask')
    const connectedAccount = getGlobalState('connectedAccount')
    const contract = await getEthereumContract()

    tx = await contract.placeBid(tokenId, {
      from: connectedAccount,
      value: toWei(price),
    })

    await tx.wait()
    await loadAuction(tokenId)
    await loadLiveLiveAuctions()
  } catch (error) {
    reportError(error);
    throw error;  // This will propagate the error back to the toast.promise

  }
}

const claimPrize = async (tokenId, bidderId) => {
  try {
    if (!ethereum) return alert('Please install Metamask')
    const connectedAccount = getGlobalState('connectedAccount')
    const contract = await getEthereumContract()

    tx = await contract.claimPrize(tokenId, bidderId, {
      from: connectedAccount,
    })

    await tx.wait()
    await loadAuction(tokenId)
    await loadBidders(tokenId)
  } catch (error) {
    reportError(error);
    throw error;  // This will propagate the error back to the toast.promise

  }
}

const offerItemOnMarket = async ({
  tokenId,
  biddable,
  sec,
  min,
  hour,
  day,
  price 
}) => {
  try {
    if (!ethereum) return alert('Please install Metamask')
    const connectedAccount = getGlobalState('connectedAccount')
    const contract = await getEthereumContract()

    tx = await contract.offerAuction(tokenId, biddable, sec, min, hour, day,toWei(price), {
      from: connectedAccount,
    })

    await tx.wait()
    await loadCollection()
    await loadLiveLiveAuctions()
  } catch (error) {
    reportError(error);
    throw error;  // This will propagate the error back to the toast.promise

  }
}

const loadCollection = async () => {
  try {
    if (!ethereum) return alert('Please install Metamask')
    const connectedAccount = getGlobalState('connectedAccount')
    const contract = await getEthereumContract()
    let collection = await contract.getMyAuctions({ from: connectedAccount })

    const tokenPromises = collection.map(async (auction) => {
      const rawUri = await contract.tokenURI(auction.tokenId);
      const cleanUri = rawUri.replace('ipfs://', 'https://ipfs.io/ipfs/');
      const metadata = await axios.get(cleanUri);

      return {
        ...auction,
        image: metadata.data.image.replace('ipfs://', 'https://ipfs.io/ipfs/'), // Get image from metadata
        // Or if you want to use a hardcoded URL:
        // image: "https://ipfs.io/ipfs/QmWbJNfqiPUMRcn92s8fpDiQysmQvwqRrRfUxyhTswDc6a/3.png",
        name: metadata.data.name, // Get name from metadata
        description: metadata.data.description, // Get description from metada
      };
    });

    collection = await Promise.all(tokenPromises);

    setGlobalState('collections', structuredAuctions(collection))
  } catch (error) {
    reportError(error)
    throw error;  // This will propagate the error back to the toast.promise

  }
}


const loadBidders = async (tokenId) => {
  try {
    if (!ethereum) return alert('Please install Metamask')
    const contract = await getEthereumContract()
    const bidders = await contract.getBidders(tokenId)
    setGlobalState('bidders', structuredBidders(bidders))
  } catch (error) {
    reportError(error)
    throw error;  // This will propagate the error back to the toast.promise

  }
}

const loadAuction = async (tokenId) => {
  try {
    if (!ethereum) return alert('Please install Metamask')
    const contract = await getEthereumContract()
    let auction = await contract.getAuction(tokenId)

    // Fetch the token metadata
    const rawUri = await contract.tokenURI(tokenId);
    const cleanUri = rawUri.replace('ipfs://', 'https://ipfs.io/ipfs/');
    const metadata = await axios.get(cleanUri);

    // Create a new object with the new image URL
    const updatedAuction = {
      ...auction,
     image: metadata.data.image.replace('ipfs://', 'https://ipfs.io/ipfs/'),
      // Or if you want to use a hardcoded URL:
      //  image: "https://ipfs.io/ipfs/QmWbJNfqiPUMRcn92s8fpDiQysmQvwqRrRfUxyhTswDc6a/5.png",
      name: metadata.data.name, // Get name from metadata
      description: metadata.data.description, // Get description from metada
    };

    setGlobalState('auction', structuredAuctions([updatedAuction])[0])
  } catch (error) {
    reportError(error)
    throw error;  // This will propagate the error back to the toast.promise

  }
}



const loadLiveAuctions = async (filters = {}) => {
  try {
    if (!ethereum) return alert('Please install Metamask');
    const contract = await getEthereumContract();
    let auctions = await contract.getAuctions();
    auctions = structuredAuctions(auctions);

    // Add a filter here:
    if (filters['NFT Status'] && filters['NFT Status'].includes('listed')) {
      auctions = auctions.filter((auction) => auction.duration > Date.now());
    }

    const tokenPromises = auctions.map(async (auction) => {
      const rawUri = await contract.tokenURI(auction.tokenId);
      const cleanUri = rawUri.replace('ipfs://', 'https://ipfs.io/ipfs/');
      const metadata = await axios.get(cleanUri);

      const attributes = metadata.data.attributes;
      const attributeValues = {};
      attributes.forEach(attribute => {
        attributeValues[attribute.trait_type] = attribute.value;
      });

      for (const [filterType, filterValues] of Object.entries(filters)) {
        if (filterValues.length > 0 && attributeValues[filterType]) {
          if (Array.isArray(filterValues)) {
            if (!filterValues.includes(attributeValues[filterType])) {
              return null; // Skip this auction if it doesn't match any filter values
            }
          } else {
            if (attributeValues[filterType] !== filterValues) {
              return null; // Skip this auction if it doesn't match the filter value
            }
          }
        }
      }

      return {
        ...auction,
        image: metadata.data.image.replace('ipfs://', 'https://ipfs.io/ipfs/'), // Get image from metadata
        // image: "https://ipfs.io/ipfs/QmWbJNfqiPUMRcn92s8fpDiQysmQvwqRrRfUxyhTswDc6a/4.png", // Get image from metadata
        attributes: attributeValues,
        name: metadata.data.name, // Get name from metadata
        description: metadata.data.description, // Get description from metada
      };
    });

    const filteredAuctions = await Promise.all(tokenPromises);
    auctions = filteredAuctions.filter(auction => auction !== null);

    setGlobalState('auctions', auctions);
  } catch (error) {
    reportError(error);
    throw error;  // This will propagate the error back to the toast.promise

  }
};


const loadSoldAuctions = async () => {
  try {
    if (!ethereum) return alert('Please install Metamask')
    const contract = await getEthereumContract()
    let auctions = await contract.getSoldAuctions()

    const tokenPromises = auctions.map(async (auction) => {
      const rawUri = await contract.tokenURI(auction.tokenId);
      const cleanUri = rawUri.replace('ipfs://', 'https://ipfs.io/ipfs/');
      const metadata = await axios.get(cleanUri);

      return {
        ...auction,
        image: metadata.data.image.replace('ipfs://', 'https://ipfs.io/ipfs/'), // Get image from metadata
        // Or if you want to use a hardcoded URL:
        // image: "https://ipfs.io/ipfs/QmWbJNfqiPUMRcn92s8fpDiQysmQvwqRrRfUxyhTswDc6a/4.png",
        name: metadata.data.name, // Get name from metadata
        description: metadata.data.description, // Get description from metada
      };
    });

    auctions = await Promise.all(tokenPromises);

    setGlobalState('auctions', structuredAuctions(auctions))
  } catch (error) {
    reportError(error);
    throw error;  // This will propagate the error back to the toast.promise

  }
}

const loadLiveLiveAuctions = async () => {
  try {
    if (!ethereum) return alert('Please install Metamask')
    const contract = await getEthereumContract()
    let auctions = await contract.getLiveAuctions()
    auctions = structuredAuctions(auctions)
    auctions = auctions.filter((auction) => auction.duration > Date.now())
    const tokenPromises = auctions.map(async (auction) => {
      const rawUri = await contract.tokenURI(auction.tokenId);
      const cleanUri = rawUri.replace('ipfs://', 'https://ipfs.io/ipfs/');
      const metadata = await axios.get(cleanUri);

      return {
        ...auction,
        image: metadata.data.image.replace('ipfs://', 'https://ipfs.io/ipfs/'), // Get image from metadata
        // Or if you want to use a hardcoded URL:
        // image: "https://ipfs.io/ipfs/QmWbJNfqiPUMRcn92s8fpDiQysmQvwqRrRfUxyhTswDc6a/4.png",
        name: metadata.data.name, // Get name from metadata
        description: metadata.data.description, // Get description from metada
      };
    });

    auctions = await Promise.all(tokenPromises);
    setGlobalState('auctions', auctions)
  } catch (error) {
    reportError(error)
  }
}





const reportError = (error) => {
  try {
    throw new Error(error);
  } catch (error) {
     console.log(error.message)
  }
}

const structuredAuctions = (auctions) =>
  auctions
    .map((auction) => ({
      tokenId: auction.tokenId.toNumber(),
      owner: auction.owner.toLowerCase(),
      seller: auction.seller.toLowerCase(),
      winner: auction.winner.toLowerCase(),
      name: auction.name,
      description: auction.description,
      duration: Number(auction.duration + '000'),
      bids: auction.bids.toNumber(),
      image: auction.image,
      price: fromWei(auction.price),
      biddable: auction.biddable,
      sold: auction.sold,
      live: auction.live,
      
    }))
    .reverse()

const structuredBidders = (bidders) =>
  bidders
    .map((bidder, i) => ({
      bidderId: i,
      timestamp: Number(bidder.timestamp + '000'),
      bidder: bidder.bidder.toLowerCase(),
      price: fromWei(bidder.price),
      refunded: bidder.refunded,
      won: bidder.won,
    }))
    .sort((a, b) => b.price - a.price)

export {
  getEthereumContract,
  isWalletConnected,
  connectWallet,
  checkIfWhitelisted,
  createNFTItem,
  createNFTItemFree,
  loadCollection,
  updatePrice,
  offerItemOnMarket,
  loadLiveAuctions,
  loadSoldAuctions,
  loadLiveLiveAuctions,
  purchaseNFT,
  placeBid,
  loadAuction,
  loadBidders,
  claimPrize,
}





